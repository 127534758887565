/* eslint-disable filenames/match-exported */

import {isValid} from 'date-fns';
import IBAN from 'iban';
import {defineRule} from 'vee-validate';

function asString(value: unknown): string {
  if (typeof value === 'string') {
    return value;
  }
  if (typeof value === 'undefined') {
    return '';
  }
  if (value instanceof File) {
    return value.name;
  }

  console.warn('Non-string value passed to validator', typeof value, value);
  return '';
}

function asNumber(value: unknown): number {
  if (typeof value === 'number') {
    return value;
  }

  if (typeof value === 'undefined') {
    return 0;
  }

  if (typeof value === 'string') {
    const number = Number.parseFloat(value);
    if (!Number.isNaN(number)) {
      return number;
    }
  }

  console.warn('Invalid numeric value passed to validator', typeof value, value);
  return 0;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export default function installRules(): void {
  defineRule('required', (value: unknown) => {
    if (typeof value === 'number') {
      return true;
    }

    const stringValue = asString(value);

    return !stringValue || !stringValue.length ? 'validation.required' : true;
  });

  defineRule('email', (value: unknown) => {
    const stringValue = asString(value);

    // Email regex
    // eslint-disable-next-line max-len
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(stringValue) ? true : 'validation.email';
  });

  defineRule('minLength', (value: unknown, args: any[] | Record<string, any>) => {
    const stringValue = asString(value);

    if (!stringValue) {
      // Allow empty values, if required the required rule should be added
      return true;
    }

    const min = Array.isArray(args) ? Number(args[0]) : 0;
    return stringValue && stringValue.length >= min ? true : `validation.min-length$$${JSON.stringify({min})}`;
  });

  defineRule('maxLength', (value: unknown, args: any[] | Record<string, any>) => {
    const stringValue = asString(value);

    const max = Array.isArray(args) ? Number(args[0]) : 0;
    return !stringValue || stringValue.length <= max ? true : `validation.max-length$$${JSON.stringify({max})}`;
  });

  defineRule('numeric', (value: unknown) => {
    if (typeof value === 'number') {
      return true;
    }

    const stringValue = asString(value);

    const re = /^\d*$/;
    return re.test(stringValue) ? true : 'validation.numeric';
  });

  defineRule('min', (value: unknown, args: any[] | Record<string, any>) => {
    const numberValue = asNumber(value);

    const min = Array.isArray(args) ? Number(args[0]) : 0;
    return (numberValue < min) ? `validation.min$$${JSON.stringify({min})}` : true;
  });

  defineRule('max', (value: unknown, args: any[] | Record<string, any>) => {
    const numberValue = asNumber(value);

    const max = Array.isArray(args) ? Number(args[0]) : 0;
    return (numberValue > max) ? `validation.max$$${JSON.stringify({max})}` : true;
  });

  defineRule('invalidValues', (value: unknown, args: any[] | Record<string, any>) => {
    if (!Array.isArray(args)) {
      return true;
    }

    if (!Array.isArray(args[0])) {
      return args.slice(0, -1).includes(value) ? args.slice(-1)[0] : true;
    }

    return args[0].includes(value) ? args[1] : true;
  });

  defineRule('postalCode', (value: unknown) => {
    const stringValue = asString(value);

    if (!stringValue) {
      // Allow empty values, if required the required rule should be added
      return true;
    }

    // Simple postal code regex
    const re = /^[0-9]{4}[A-Z]{2}$/i;
    return re.test(stringValue) ? true : 'validation.postal-code-invalid';
  });

  defineRule('iban', (value: unknown) => {
    const stringValue = asString(value);

    if (!stringValue) {
      // Allow empty values, if required the required rule should be added
      return true;
    }

    return IBAN.isValid(stringValue) ? true : 'validation.iban-invalid';
  });

  /**
   * BIC is the business identifier code (ISO 9362). This BIC check is not a guarantee for authenticity.
   *
   * BIC pattern: BBBBCCLLbbb (8 or 11 characters long; bbb is optional)
   *
   * Validation is case-insensitive. Please make sure to normalize input yourself.
   *
   * BIC definition in detail:
   * - First 4 characters - bank code (only letters)
   * - Next 2 characters - ISO 3166-1 alpha-2 country code (only letters)
   * - Next 2 characters - location code (letters and digits)
   *   a. shall not start with '0' or '1'
   *   b. second character must be a letter ('O' is not allowed) or digit
   *      ('0' for test (therefore not allowed), '1' denoting passive participant, '2' typically reverse-billing)
   * - Last 3 characters - branch code, optional (shall not start with 'X' except in case of 'XXX' for primary office) (letters and digits)
   *
   * Source: https://github.com/jquery-validation/jquery-validation/blob/master/src/additional/bic.js
   */
  defineRule('bic', (value: unknown) => {
    const stringValue = asString(value);

    if (!stringValue) {
      // Allow empty values, if required the required rule should be added
      return true;
    }

    return /^([A-Z]{6}[A-Z2-9][A-NP-Z1-9])(X{3}|[A-WY-Z0-9][A-Z0-9]{2})?$/.test(stringValue) ? true : 'validation.bic-invalid';
  });

  defineRule('vatNumber', (value: unknown) => {
    const stringValue = asString(value);

    if (!stringValue) {
      // Allow empty values, if required the required rule should be added
      return true;
    }

    // VAT number regex, based on https://en.wikipedia.org/wiki/VAT_identification_number
    const re = /[A-Z]{2}[A-Z0-9]{2,13}$/i;
    return re.test(stringValue) ? true : 'validation.vat-invalid';
  });

  defineRule('phoneNumber', (value: unknown) => {
    const stringValue = asString(value);

    // As defined by the pattern for the Contact API object
    const re = /^0[1-8][0-9]{8}$/;
    return re.test(stringValue) ? true : 'validation.phone-number-invalid';
  });

  defineRule('passwordMatch', (value: unknown, args: any[] | Record<string, any>) => {
    const stringValue = asString(value);
    const otherValue = Array.isArray(args) ? asString(args[0]) : '';

    return stringValue === otherValue ? true : 'validation.passwords-must-match';
  });

  defineRule('date', (value: unknown) => {
    return isValid(Date.parse(asString(value))) ? true : 'validation.date-invalid';
  });
}
