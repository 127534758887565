import {COMPANY_101010, COMPANY_A2B, COMPANY_DC1, type CompanyType, is101010tv, isA2B, isDC1, isHmr} from '@/utils';
import {AppFeatures} from '@plugins/features/AppFeatures';
import {Features} from '@plugins/injections';
import {type App, inject, reactive, readonly} from 'vue';

// Feature configuration
const enabledFeatures: Record<AppFeatures, boolean> = reactive({
  [AppFeatures.dcConnections]: isA2B() || isDC1(),
  [AppFeatures.ipManagement]: isA2B() || isDC1(),
  [AppFeatures.myInvoices]: true,
  [AppFeatures.myLines]: isA2B() || isDC1(),
  [AppFeatures.myOrders]: isA2B() || isDC1(),
  [AppFeatures.postalCodeCheck]: isA2B(),
  [AppFeatures.quotation]: isA2B() || isDC1(),
  [AppFeatures.rackSpace]: isA2B() || isDC1(),
  [AppFeatures.tenTenTenTv]: is101010tv(),
  [AppFeatures.userManagement]: true,
  [AppFeatures.wdm]: isA2B() || isDC1(),
});

const companyFeatures: Record<AppFeatures, CompanyType[]> = {
  [AppFeatures.dcConnections]: [COMPANY_A2B, COMPANY_DC1],
  [AppFeatures.ipManagement]: [COMPANY_A2B, COMPANY_DC1],
  [AppFeatures.myInvoices]: [COMPANY_A2B, COMPANY_DC1, COMPANY_101010],
  [AppFeatures.myLines]: [COMPANY_A2B, COMPANY_DC1],
  [AppFeatures.myOrders]: [COMPANY_A2B, COMPANY_DC1],
  [AppFeatures.postalCodeCheck]: [COMPANY_A2B],
  [AppFeatures.quotation]: [COMPANY_A2B, COMPANY_DC1],
  [AppFeatures.rackSpace]: [COMPANY_A2B, COMPANY_DC1],
  [AppFeatures.tenTenTenTv]: [COMPANY_101010],
  [AppFeatures.userManagement]: [COMPANY_A2B, COMPANY_DC1, COMPANY_101010],
  [AppFeatures.wdm]: [COMPANY_A2B, COMPANY_DC1],
};

class FeatureServiceClass {
  constructor() {
    if (isHmr()) {
      this.unlockAll();
    }
  }

  public featureEnabled(feature: AppFeatures): boolean {
    return enabledFeatures[feature] ?? false;
  }

  public get features(): Record<AppFeatures, boolean> {
    return readonly(enabledFeatures);
  }

  public unlockAll(): void {
    Object.values(AppFeatures)
      .forEach((feature) => {
        if (companyFeatures[feature].includes(import.meta.env.VITE_COMPANY)) {
          enabledFeatures[feature] = true;
        }
      });
  }
}

export type FeatureService = InstanceType<typeof FeatureServiceClass>;

export function useFeatures(): FeatureService {
  const features = inject(Features);
  if (!features) {
    throw new Error('Feature configuration not available, did you enable the features plugin?');
  }

  return features;
}

export default function(app: App): void {
  app.provide(Features, new FeatureServiceClass());
}
