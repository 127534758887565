export enum AppFeatures {
  dcConnections = 'dcConnections',
  ipManagement = 'ipManagement',
  myInvoices = 'myInvoices',
  myLines = 'myLines',
  myOrders = 'myOrders',
  postalCodeCheck = 'postalCodeCheck',
  quotation = 'quotation',
  rackSpace = 'rackSpace',
  tenTenTenTv = '101010tv',
  userManagement = 'userManagement',
  wdm = 'wdm',
}
